import {
    IconShoppingBag
} from '@tabler/icons-vue';

export interface menu {
    header?: string;
    title?: string;
    icon?: any;
    to?: string;
    chip?: string;
    chipColor?: string;
    chipVariant?: string;
    chipIcon?: string;
    children?: menu[];
    disabled?: boolean;
    type?: string;
    subCaption?: string;
}

const sidebarItem: menu[] = [
    { header: 'Home' },
    {
        title: 'Home',
        icon: IconShoppingBag,
        to: '/'
    },
    // {
    //     title: "E-Commerce",
    //     icon: IconBasket,
    //     to: "/ecommerce/",
    //     children: [
    //       {
    //         title: "Shop",
    //         icon: PointIcon,
    //         to: "/apps/ecommerce/products",
    //       },
    //       {
    //         title: "Detail",
    //         icon: PointIcon,
    //         to: "/apps/ecommerce/product/detail/1",
    //       },
    //       {
    //         title: "List",
    //         icon: PointIcon,
    //         to: "/apps/ecommerce/productlist",
    //       },
    //       {
    //         title: "Checkout",
    //         icon: PointIcon,
    //         to: "/apps/ecommerce/checkout",
    //       },
    //     ],
    //   },
    // { header: 'utilities' },
    // {
    //     title: 'Typography',
    //     icon: TypographyIcon,
    //     to: '/ui/typography'
    // },
    // {
    //     title: 'Shadow',
    //     icon: CopyIcon,
    //     to: '/ui/shadow'
    // },
    // { header: 'auth' },
    // {
    //     title: 'Login',
    //     icon: LoginIcon,
    //     to: '/auth/login'
    // },
    // {
    //     title: 'Register',
    //     icon: UserPlusIcon,
    //     to: '/auth/register'
    // },
    // { header: 'Extra' },
    // {
    //     title: 'Icons',
    //     icon: MoodHappyIcon,
    //     to: '/icons'
    // },
    // {
    //     title: 'Sample Page',
    //     icon: ApertureIcon,
    //     to: '/sample-page'
    // },
    // { header: 'Test' },
    // {
    //     title: 'Users',
    //     icon: UsersIcon,
    //     to: '/tests/users'
    // },
];

export default sidebarItem;
