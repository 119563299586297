<script setup lang="ts">
import { IconPower } from '@tabler/icons-vue';
const authStore = useAuthStore();
</script>

<template>
  <v-sheet rounded="md" color="lightsecondary" class="px-4 py-3 ExtraBox">
    <div class="d-flex align-center hide-menu">
      <!-- <v-avatar size="40">
        <img src="/images/profile/user-1.jpg" alt="user" height="40" />
      </v-avatar> -->
      <div class="ml-4">
        <span class="text-subtitle-2 textSecondary">Hola</span>
        <h4 class="mb-n1 text-h6 textPrimary">Jorge Mario</h4>
      </div>
      <div class="ml-auto">
        <v-btn
          variant="text"
          icon
          rounded="md"
          color="primary"
          @click="authStore.logout()"
        >
          <IconPower />
          <v-tooltip activator="parent" location="top">{{ $t('Logout') }}</v-tooltip>
        </v-btn>
      </div>
    </div>
  </v-sheet>
</template>
<style lang="scss">
.ExtraBox {
  position: relative;
  overflow: hidden;
}
.line-height-none {
  line-height: normal;
}
</style>
