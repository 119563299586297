<script setup lang="ts">
import { ref, computed } from 'vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import { useI18n } from 'vue-i18n'
import { IconBarcode, IconForms, IconLoader, IconX } from '@tabler/icons-vue';
import loadingCheckoutImage from '/public/images/lottie_files/checkout-loading.json';
import cornerCamera from '@images/svgs/corner-camera.svg';

const { t } = useI18n() 

defineProps({
  unpaused: Boolean
})

/*** detection handling ***/
const result = ref()
const paused = ref(false)
const loading = ref(true)


const emit = defineEmits(['onBarcodeRead','onError'])

async function onDetect(detectedCodes:any) {
  result.value = detectedCodes.map((code:any) => code.rawValue)
  paused.value = true;
  if (result.value.length > 1){
    console.info("result > 1: ", result.value, detectedCodes)
  } else {
    console.info("result = 1: ", result.value, detectedCodes.map((code:any) => code.boundingBox))
  }
  if (result.value.length > 0){
    emit('onBarcodeRead', result.value.join(','))
    await timeout(300);
  } else {
    emit('onError', true)
  }
  // paused.value = false;
}

function timeout(ms:number) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms)
  })
}

/*** select camera ***/
const selectedConstraints = ref({ facingMode: 'environment' })
const defaultConstraintOptions = [
  { label: 'Cámara Posterior', constraints: { facingMode: 'environment' } },
  { label: 'Cámara Frontal', constraints: { facingMode: 'user' } }
]
const constraintOptions = ref(defaultConstraintOptions)

async function onCameraReady() {
  // NOTE: on iOS we can't invoke `enumerateDevices` before the user has given
  // camera access permission. `QrcodeStream` internally takes care of
  // requesting the permissions. The `camera-on` event should guarantee that this
  // has happened.
  const devices = await navigator.mediaDevices.enumerateDevices()
  const videoDevices = devices.filter(({ kind }) => kind === 'videoinput')
  paused.value = false;
  loading.value = false;

  constraintOptions.value = [
    ...defaultConstraintOptions,
    ...videoDevices.map(({ deviceId, label }) => ({
      label: `${label}`, //  (ID: ${deviceId})
      constraints: { deviceId }
    }))
  ]

  error.value = ''
}

/*** track functons ***/
function paintOutline(detectedCodes:any, ctx:any) {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = '#FA896B'
    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
    return;
  }
}
function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 2
    ctx.strokeStyle = '#FA896B'
    ctx.strokeRect(x, y, width, height)
    return; // solo para que pinte el primero detectado?
  }
}
function paintCenterText(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const { boundingBox, rawValue } = detectedCode

    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2

    const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

    ctx.font = `bold ${fontSize}px sans-serif`
    ctx.textAlign = 'center'

    ctx.lineWidth = 2
    ctx.strokeStyle = '#FA896B'
    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

    ctx.fillStyle = '#5cb984'
    ctx.fillText(rawValue, centerX, centerY)
    return;
  }
}
const trackFunctionOptions = [
  { text: 'nothing (default)', value: undefined },
  { text: 'outline', value: paintOutline },
  { text: 'centered text', value: paintCenterText },
  { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[3])

/*** barcode formats ***/

const barcodeFormats = ref({
  code_128: false,
  ean_13: true,
  upc_a: true,
  upc_e: true,
  ean_8: true,
  aztec: false,
  code_39: false,
  code_93: false,
  codabar: false,
  databar: false,
  databar_expanded: false,
  data_matrix: false,
  dx_film_edge: false,
  itf: false,
  maxi_code: false,
  micro_qr_code: false,
  pdf417: false,
  qr_code: false,
  rm_qr_code: false,
  linear_codes: false,
  matrix_codes: false
})
const selectedBarcodeFormats = computed(() => {
  return Object.keys(barcodeFormats.value).filter((format) => barcodeFormats.value[format])
})

/*** error handling ***/

const error = ref('')

async function requestCameraAccess() {
    await navigator.mediaDevices.getUserMedia({ video: true });
}

function antiBugMethod() {
    const videoElement = document.querySelector('video.qrcode-stream-camera');
    if (videoElement) {
        videoElement.pause();
        videoElement.src = '';
        videoElement.removeAttribute('src');
        videoElement.load();
        videoElement.parentNode?.removeChild(videoElement);
    }
}

function reloadCameraFeed() {
    paused.value = true;
    setTimeout(() => {
        paused.value = false;
        console.log('Restarted camera feed.');
    }, 200);
}

async function onError(err:any) {

  await antiBugMethod();
  await reloadCameraFeed();
  await requestCameraAccess();
  await reloadCameraFeed();
  setTimeout(() => {}, 2500);

  error.value = `[${err.name}]: `
  error.value = `ERROR: `

  if (err.name === 'NotAllowedError') {
    error.value += t('errors.cameraErrors.NotAllowedError');
  } else if (err.name === 'NotFoundError') {
    error.value += t('errors.cameraErrors.NotFoundError');
  } else if (err.name === 'NotSupportedError') {
    error.value += t('errors.cameraErrors.NotSupportedError');
  } else if (err.name === 'NotReadableError') {
    error.value += t('errors.cameraErrors.NotReadableError');
  } else if (err.name === 'OverconstrainedError') {
    error.value += t('errors.cameraErrors.OverconstrainedError');
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += t('errors.cameraErrors.StreamApiNotSupportedError');
  } else if (err.name === 'InsecureContextError') {
    error.value += t('errors.cameraErrors.InsecureContextError');
  } else {
    error.value += err.message;
  }
}
</script>
<template>
  <div class="qr-barcode-container">
    <!-- <p v-if="constraintOptions.length > 2">
      <b>Seleccione cámara</b>:
      <v-select v-model="selectedConstraints" 
        :items="constraintOptions" 
        item-title="label" 
        item-value="constraints">
      </v-select>
    </p>
    <p>
      <b>visor</b>:
      <select v-model="trackFunctionSelected">
        <option
          v-for="option in trackFunctionOptions"
          :key="option.text"
          :value="option"
        >
          {{ option.text }}
        </option>
      </select>
    </p> -->

    <div class="d-none">
      <span
        v-for="option in Object.keys(barcodeFormats)"
        :key="option"
        class="barcode-format-checkbox"
      >
        <input
          type="checkbox"
          v-model="barcodeFormats[option]"
          :id="option"
        />
        <label :for="option">{{ option }}</label>
      </span>
    </div>

    <!-- <p class="decode-result">
      Last result: <b>{{ result }}</b>
    </p> -->

    <div class="h-screen" style="padding-bottom: 45vh">
      <v-alert class="pa-5 text-center mt-4 mx-2 rounded rounded-md" v-if="error" variant="tonal" color="error">
        <b>{{ error }}</b>
        <br><br>
        {{ $t('errors.cameraErrors.instructions') }}
      </v-alert>
      <div v-else-if="loading" class="mt-5 text-center">
        <Vue3Lottie :animationData="loadingCheckoutImage" width="400px" class="" style="max-height: 260px" />
        <h4 class="pa-5 text-h4">{{ paused ? 'Reactivando cámara...' : $t('basic.pleaseWait') }}</h4>
      </div>
      <div v-if="!loading" style="position: fixed; top: 28%; left: 0; right: 0; z-index: 99999">
        <div class="text-center pa-4 px-12 w-100 mt-16">
          {{ $t('scan_instructions') }}
        </div>
      </div>
      <div style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 99999">
        <div class="d-flex flex-column align-center pa-4 text-center">
          <v-btn @click="() => { paused = true; emit('onBarcodeRead', null) }" small icon flat class="text-primary mb-2" color="secondary"  >
            <IconX size="30" stroke-width="2" />
          </v-btn>
          <slot v-if="!loading || error">
            <v-btn small flat  @click="emit('onError', true)" variant="outlined" class="text-primary font-weight-light" >
              <IconForms size="22" stroke-width="0.5" class="mr-2" /> {{ $t('barcode-reader.manual-input') }}
            </v-btn>
          </slot>
        </div>
        <!-- TODO: Instrucciones multi-idioma  -->
        <div class="text-left scan-instructions-image align-end d-flex justify-end flex-column pb-3 pl-2 " >
          <div class="bg-white pa-4 rounded-lg elevation-1">
            <h2>{{ $t('scan.instructions-1') }}</h2>
            <p>{{ $t('scan.instructions-2') }}</p>
          </div>
        </div>
      </div>
      <!-- paused: {{paused}} -->
      <QrcodeStream
        :constraints="selectedConstraints"
        :track="trackFunctionSelected.value"
        :formats="selectedBarcodeFormats"
        :paused="paused"
        @error="onError"
        @detect="onDetect"
        @camera-on="onCameraReady"
        >
        <div v-if="!loading" class="camera-overlay">
          <div class="visor">
            <div style="position: absolute; width: 40px; height: 40px; top: -2px; left: -2px; transform: rotate(270deg);">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
            <div style="position: absolute; width: 40px; height: 40px; top: -2px; right: -2px;">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
            <div style="position: absolute; width: 40px; height: 40px; bottom: -2px; left: -2px; transform: rotate(180deg);">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
            <div style="position: absolute; width: 40px; height: 40px; bottom: -2px; right: -2px; transform: rotate(90deg);">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
          </div>
          <div class="gradient-bottom-overlay"></div>
        </div>
        <div v-else class="loading-indicator">
          <IconLoader size="28" stroke-width="1" color="primary" />
        </div>
      </QrcodeStream>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.qr-barcode-container {
  color: rgb(var(--v-theme-on-surface-variant));
  margin: 0px; 
  padding: 0px;
  overflow: hidden;
  background-color: rgba(var(--v-theme-surface), 0.65);
  .z-index-99 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  .barcode-format-checkbox {
    margin-right: 10px;
    white-space: nowrap;
    display: inline-block;
  }
  .camera-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .visor {
      content: '';
      position: fixed;
      top: 20%;
      left: 50%;
      width: 90%;
      height: 30%;
      background: transparent;
      border: 1px solid rgba(var(--v-theme-surface));
      border-radius: 30px;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 0 9999px rgba(var(--v-theme-surface), 0.8);
    }
    .gradient-bottom-overlay{
      content: '';
      position: absolute;
      bottom: 0%;
      left: 0%;
      width: 100%;
      height: calc(10vh);
      background: linear-gradient(180deg, rgba(var(--v-theme-surface), 0) 0%, rgba(var(--v-theme-surface), 0.9) 100%);
    }
  }
  .scan-instructions-image {
    width: 100%;
    height: 190px;
    padding-right: 130px !important;
    background-image: url(/public/images/banners/barcode_reader/instructions-background.png);
    background-size: contain;
    background-position: bottom right;
    h2 {
      line-height: 1.4rem;
      padding-bottom: 6px;
    }
    p {
      line-height: 1.15rem;
    }
  }
}
</style>